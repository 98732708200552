import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { Menu } from "../components/Menu";
import Loading from "../components/Loading/Loading";
import { format, addDays, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyProducts,removeMyProduct
} from "../store/actions/myProductAction";
import Alert from "../components/Alert/Alert";
import ToolbarPanel from "../components/MyProducts/ToolbarPanel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductRow from "../components/MyProducts/ProductRow";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",    
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    height:'70px',
    width:'70px',
    marginLeft:'auto',
    marginRight:'auto'
  }
}));


export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}

export default function MyProducts() {
  const classes = useStyles();
  const history = useHistory();
  const alertPopup = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [addWaiting, setAddWaiting] = useState(false);
  const dispatch = useDispatch();  
  const state = useSelector((state) => state);
  const user =  state.session.user;
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // const fromDate = state.week.weekSelected.first;
  // const toDate = format(
  //   addDays(parseISO(state.week.weekSelected.first), 6),
  //   "yyyy-MM-dd"
  // );
  const storesSelected = state.store.storesSelected;
  const loadDetail = true;//state.myProduct.showReportData;
  
  const [dataSource, setDataSource] = useState([]);
  const [tags, setTags] = useState(null);
  const [orderDirection, setOrderDirection] = useState(1);
  useEffect(() => {
    setIsLoading(true);
    const postData = {     
      storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
      tags:tags, 
      limit: 50,
      // loadDetail: loadDetail
      // province: state.city.citySelected.province,
    };
    dispatch(fetchMyProducts(postData)).then((result)=>{
      setDataSource(result)  
    })
    .catch(e => {
    }).finally(() => {
      setIsLoading(false);
    });
  }, [storesSelected,tags]);  
  const handleRemoveMyProduct = (productId)=>{
    let tempDataSource = dataSource.filter(item => item.product.id != productId)
    setDataSource(tempDataSource) 
    dispatch(removeMyProduct(productId)).then(()=>{   
      alertPopup.current&&alertPopup.current.doAlert("success", "Success! removed from my products."); 
    })
    .catch(e => {
      alertPopup.current&&alertPopup.current.doAlert("error", `Sorry, something went wrong.`); 
      console.log(e)
      // error("error", "Success! removed from your shopping cart");
    }).finally(() => {
      setIsLoading(false);
    });
  }
  const handleSort=(column)=>{
    setOrderDirection(-orderDirection);
    let tempDataSource = [...dataSource]
    column =='max'||column =='min'||column =='max_100g'||column =='min_100g'||column =='std'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => ((a.product[column] > b.product[column]) ? 1 : ((b.product[column] > a.product[column]) ? -1 : 0))*orderDirection);
    setDataSource(tempDataSource);
  }
  const handleRowClick =(product)=>{
    history.push(`/my-product/${product.id}`)    
  }
  const addCompleted=(product)=>{
    let tempDataSource = [...dataSource,product]
    setDataSource(tempDataSource)
    alertPopup.current&&alertPopup.current.doAlert("success", "Success! added to my product."); 
  }
  return (
    <div className={classes.root}>
      <Menu title={"Similar Product Matches "} 
          // weekSelectedButtonEnable 
          formDateToDateSelectedButtonEnable
          addProductButtonEnable={user&&user.company_id==1} 
          addCompleted ={addCompleted} 
          setAddWaiting={setAddWaiting} 
          myProductIds={dataSource.map(x=>+x.product.id)}
          selectedCityEnable
          selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {/* <ToolbarPanel doFilter={setTags}/> */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                >
                  <Table stickyHeader aria-label="my products">
                    <TableHead>
                      <TableRow>
                      <TableCell align="left"  className={classes.thHeader}></TableCell>
                      {/* <TableCell align="left"  className={classes.thHeader}></TableCell> */}
                        <TableCell align="left"  className={classes.thHeader}>                         
                          <SortLabel onClick={() => handleSort("fullname")}>
                            Name
                          </SortLabel>
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("size")}>
                            Size
                          </SortLabel>
                        </TableCell>                       
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Min $</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Max $</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Min $/100g</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>                         
                          <SortLabel hideSortIcon>Max $/100g</SortLabel>
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Volatility</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}></TableCell>                 
                      </TableRow>
                      </TableHead>
                    <TableBody>
                    {isLoading ? (
                      <TableRow className={classes.tableRow}>
                          <TableCell colSpan={9} >
                            <Loading />
                          </TableCell>                                           
                      </TableRow>
                    ) : (
                        <>
                        {dataSource &&
                          dataSource.map((row, index) => {
                            return (
                              <ProductRow key={index} product={row} onClick={handleRowClick} onRemoveCommand={handleRemoveMyProduct}/>
                            )})}
                        {addWaiting&&(
                          <TableRow className={classes.tableRow}>
                            <TableCell >
                              <span  className={classes.waitingPicture}>
                              <CircularProgress color="success" />
                              </span>
                            </TableCell>  
                            <TableCell colSpan={8} >
                              <span  className={classes.waitingRow}>
                                <Skeleton  duration={2} />
                              </span>
                            </TableCell>                                           
                          </TableRow>
                        )} 
                        </>
                    )}
                    </TableBody>
                    <TableFooter>
                    
                    </TableFooter>
                  </Table>
                </TableContainer>              
            </Grid>
          </Grid>
        </Container>
        
      </main>
      <Alert ref={alertPopup} />
    </div>
  );
}
